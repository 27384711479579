<template>
  <div class="left_page">
    <div class="top">
      <img src="../../../../assets/newDistrict/leftTop_tip.png" alt="">
      <div class="cup">
        <img src="../../../../assets/newDistrict/cupOnline.png" alt="">
        <div>
          <p>{{ rate }} <img  class="tip" v-if="rateexceed==true" src="../../../../assets/newDistrict/tip.png" alt="">
          </p>
          <p>杯具洗消在线率</p>
        </div>
      </div>

    </div>
    <div class="center">
      <div class="center_content">
        <img src="../../../../assets/newDistrict/leftCenter_Tit.png" alt="">
        <div class="list" v-if="socketList.length > 0">
          <el-row class="title">
            <el-col :span="9">酒店名称</el-col>
            <el-col :offset="1" :span="14">插座点位</el-col>
          </el-row>
          <div class="swiper swiper_socket">
            <div class="swiper-wrapper">
              <el-row v-for="(el, index) in socketList" class="swiper-slide" :key="index">
                <el-col :span="9">{{ el.siteName }}</el-col>
                <el-col :offset="1" :span="14">{{ el.points }}</el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="listImg" v-else>
          <img src="../../../../assets/newDistrict/socketOnline.png" alt="">
          <p>本区域内所有插座均已在线</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../../../../assets/newDistrict/leftBottom_Tit.png" alt="">
      <div class="content">
        <div class="box">
          <img src="../../../../assets/newDistrict/ware.png" alt="">
          <div>
            <p>{{ totalData.roomCount }}</p>
            <p>洗消间数量</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../../assets/newDistrict/camera.png" alt="">
          <div>
            <p>{{ totalData.cameraCount }}</p>
            <p>摄像头数量</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../../assets/newDistrict/socket.png" alt="">
          <div>
            <p>{{ totalData.boardCount }}</p>
            <p>插座数量</p>
          </div>
        </div>
        <div style="width: 50%;text-align: center;">
          <p>{{ bottomData.runtime ? parseInt(bottomData.runtime / 3600) : 0 }}</p>
          <p>本周消毒柜时长（小时）</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getboardOffLineSite, getCup, getCupandWareRate } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data() {
    return {
      socketList: [],
      totalData: {
        areaCount: 0,
        boardCount: 0,
        cameraCount: 0,
        roomCount: 0,
        siteCount: 0,
        visitorCount: 0
      },
      bottomData: {},
      rate: '',
      rateexceed:false
    }
  },
  computed: {
  },
  created() {
    this.getRate()
  },
  methods: {
    init(val) {
      const adcode = val[0].adcode
      getboardOffLineSite(adcode).then(res => {
        if (res.code == 0 && res.message == 'success') {
          res.data.map(i => {
            i.points = i.boardPointList.join(',')
          })
          this.socketList = res.data
          // if (res.data.length > 6) {
            this.initSwiper()
          // }
        }
      })
    },
    initSwiper() {
      this.$nextTick(() => {
        if (this.socketList && this.socketList.length <= 6) {
          new Swiper('.swiper_socket', {
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: false,
            autoplay:false,
            // speed: 1500
          })
        }
        if (this.socketList && this.socketList.length > 6) {
          new Swiper('.swiper_socket', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }

      })
    },
    getbottomData(val) {
      getCup().then(res => {
        this.bottomData = res.data
      })
      this.totalData = val


    },
    getRate() {
      getCupandWareRate({
        areaCode: localStorage.getItem('areaCode'),
        Authorization: localStorage.getItem('token'),
        statTypes: [7],
        startTime: '2024-07-15',
        endTime: this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
      }).then(res => {
        if (res.code == 0 && res.message == 'success') {
          let rate = parseFloat(res.data[0].rate);
          if (isNaN(rate)) {
            this.rate = '-';
          } else {
            if (rate > 1) {
              this.rate = '100%';
              this.rateexceed = true;
            } else {
              this.rate = (rate * 100).toFixed(0) + '%';
              this.rateexceed = false;
            }
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}

p {
  padding: 0 !important;
  line-height: 1 !important;
  margin: 0 !important;
}

.left_page {
  width: 609px;
  height: 961px;
  background: url('~@/assets/newDistrict/left.png') no-repeat center center;
  margin-right: 24px;
  padding-top: 16px;
  background-size: 100% 100%;

  .top {
    position: relative;
    padding-top: 66px;
    padding: 66px 70px 0;
    width: 100%;

    img {
      width: 460px;
    }

    .cup {
      margin: 20px 0;
      width: 460px;
      height: 118px;
      background: rgba(40, 217, 250, 0.2);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 118px;
        height: 82px;
      }

      div {
        p {
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          padding-top: 18px !important;
        }

        p:first-child {
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 32px;
          color: #3AFFE4;
          margin-bottom: 10px;
          padding: 0 !important;
          display: flex;
          align-items: center;
          .tip{
            width: 15px;
        height: 15px;
        margin: 0;
        margin-left: 10px;
          }
          
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      display: block;
      background: url('~@/assets/newDistrict/leftTop_Tit.png') no-repeat center center;
      width: 582px;
      height: 33px;
    }
  }

  .center {
    width: 100%;
    height: 344px;

    .center_content {
      padding: 0 21px 0 27px;

      img {
        margin: 20px 0 28px;
      }

      .listImg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          width: 307px;
          height: 222px;
          margin: 0;
        }

        p {
          width: 201px;
          height: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #3AFFE4;
          line-height: 80px;
        }
      }

      .list {
        padding-bottom: 10px;


        .title {
          color: #28d4f5;
          font-size: 16px;
          width: 100%;
          font-weight: 500;
          padding-left: 9px;
          margin-bottom: 15px;
        }

        .swiper_socket {
          overflow: hidden;
          height: 180px;
          width: 100%;

          .swiper-slide {
            background: rgba(68, 202, 255, 0.4);
            padding: 10px;
            height: 30px;
            color: white;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 30px;

            .el-col-9 {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .swiper-slide:nth-child(2n) {
            background: transparent;
          }
        }

      }



    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 24px;
      background: url('~@/assets/newDistrict/borderLine.png') no-repeat center center;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 24px;
      background: url('~@/assets/newDistrict/borderLine.png') no-repeat center center;
    }
  }

  .bottom {
    padding: 20px 21px 0 27px;

    .content {
      height: 300px;
      padding: 25px 29px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: stretch;

      p {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        color: #44CAFF;

        &:last-child {
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #FDFDFD;
          margin-top: 15px !important;
        }
      }

      .box {
        width: 50%;
        height: 100px;
        display: flex;
        align-items: center;

        img {
          width: 90px;
          height: 85px;
          margin-right: 25px;
        }

        p {
          color: #FFFFFF;
        }


      }
    }
  }
}
</style>
