<template>
  <div class="right_page">
    <div class="top">
      <img src="../../../../assets/newDistrict/rightTop_tip.png" alt="">
      <div class="cup">
        <img src="../../../../assets/newDistrict/wareOnline.png" alt="">
        <div>
          <p>{{ rate }} <img v-if="rateexceed==true" class="tip" src="../../../../assets/newDistrict/tip.png" alt="">
          </p>
          <p>洁具清洁使用率</p>
        </div>
      </div>

    </div>
    <div class="center">
      <div class="center_content">
        <img src="../../../../assets/newDistrict/rightCenter_Tit.png" alt="">
        <div class="list">
          <el-row class="title">
            <el-col :span="8">酒店名称</el-col>
            <el-col :offset="1" :span="7">昨日使用率</el-col>
            <el-col :offset="1" :span="7">今日使用率</el-col>
          </el-row>
          <div class="swiper swiper_content" v-if="wareuseList.length > 0">
            <div class="swiper-wrapper">
              <el-row v-for="(el, index) in wareuseList" class="swiper-slide" :key="index">
                <el-col :span="8">{{ el.siteName }}</el-col>
                <el-col :offset="1" :span="7" style="display: flex;align-items: center;">
                  <span>{{ el.yesterdayUseRate }}</span> <img src="../../../../assets/newDistrict/tip.png" alt=""
                    class="tip" v-if="el.yesterdayexceed == true"></el-col>
                <el-col :offset="1" :span="7" style="display: flex;align-items: center;">
                  <span>{{ el.todayUseRate }}</span><img src="../../../../assets/newDistrict/tip.png" alt="" class="tip"
                    v-if="el.todayexceed == true"></el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../../../../assets/newDistrict/rightBottom_Tit.png" alt="">
      <div class="content">
        <div class="box">
          <img src="../../../../assets/newDistrict/room.png" alt="">
          <div>
            <p>{{ itemMsg.roomSum }}</p>
            <p>房间数量</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../../assets/newDistrict/device.png" alt="">
          <div>
            <p>{{ itemMsg.sanitaryWareRfidDeviceSum }}</p>
            <p>读卡器数量</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../../assets/newDistrict/cleaner.png" alt="">
          <div>
            <p>{{ cleanerCount }}</p>
            <p>保洁员数量</p>
          </div>
        </div>
        <div style="width: 50%;text-align: center;">
          <p>{{ itemMsg.braceletRoomSum }}</p>
          <p>本周打扫房间数量（间）</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWareUseRate, getCleaner, getRight, getCupandWareRate } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data() {
    return {
      wareuseList: [],
      itemMsg: {},
      rate: '',
      cleanerCount: null,
      rateexceed: false,
    }
  },
  computed: {
  },
  created() {
    // this.init()
    this.getRate()
    this.getData()
  },
  methods: {
    init(val) {
      const adcode = val[0].adcode
      getWareUseRate(adcode).then(res => {
        if (res.code == 0 && res.message == 'success') {
          res.data.map(i => {
            // 处理 todayUseRate
            if (typeof i.todayUseRate === 'number' && !isNaN(i.todayUseRate)) {
              if (i.todayUseRate > 1) {
                i.todayUseRate = '100%';
                i.todayexceed = true;
              } else {
                i.todayUseRate = (i.todayUseRate * 100).toFixed(0) + '%';
                i.todayexceed = false;
              }
            } else {
              i.todayUseRate = '-';
              i.todayexceed = false;
            }

            // 处理 yesterdayUseRate
            if (typeof i.yesterdayUseRate === 'number' && !isNaN(i.yesterdayUseRate)) {
              if (i.yesterdayUseRate > 1) {
                i.yesterdayUseRate = '100%';
                i.yesterdayexceed = true;
              } else {
                i.yesterdayUseRate = (i.yesterdayUseRate * 100).toFixed(0) + '%';
                i.yesterdayexceed = false;
              }
            } else {
              i.yesterdayUseRate = '-';
              i.yesterdayexceed = false;
            }
          });
          this.wareuseList = res.data
          this.initSwiper()
        }
      })
    },
    initSwiper() {
      this.$nextTick(() => {
        if (this.wareuseList && this.wareuseList.length > 6) {
          new Swiper('.swiper_content', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.wareuseList && this.wareuseList.length <= 6) {
          new Swiper('.swiper_content', {
            direction: 'vertical',
            slidesPerView: 'auto',
            speed: 1500
          })
        }
      })
    },
    getRate() {
      getCupandWareRate({
        areaCode: localStorage.getItem('areaCode'),
        Authorization: localStorage.getItem('token'),
        statTypes: [9],
        startTime: '2024-07-15',
        endTime: this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
      }).then(res => {
        if (res.code == 0 && res.message == 'success') {
          let rate = parseFloat(res.data[0].rate);
          if (isNaN(rate)) {
            this.rate = '-';
          } else {
            if (rate > 1) {
              this.rate = '100%';
              this.rateexceed = true;
            } else {
              this.rate = (rate * 100).toFixed(0) + '%';
              this.rateexceed = false;
            }
          }
        }
      })
    },
    getData() {
      getCleaner().then(res => {
        this.cleanerCount = res.data
      })
      getRight().then(res => {
        this.itemMsg = res.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}

p {
  padding: 0 !important;
  line-height: 1 !important;
  margin: 0 !important;
}

.right_page {
  width: 609px;
  height: 961px;
  background: url('~@/assets/newDistrict/left.png') no-repeat center center;
  margin-left: 24px;
  padding-top: 16px;
  background-size: 100% 100%;

  .top {
    position: relative;
    padding-top: 66px;
    padding: 66px 40px 0;
    width: 100%;

    img {
      // width: 528px;
      height: 33px;
    }

    .cup {
      margin: 20px 33px;
      width: 460px;
      height: 118px;
      background: rgba(40, 217, 250, 0.2);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 118px;
        height: 84px;
      }

      

      div {
        p {
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          padding-top: 18px !important;

        }

        p:first-child {
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 32px;
          color: #3AFFE4;
          margin-bottom: 10px;
          padding-top: 0 !important;
          display:flex ;
          align-items: center;
          .tip {
        width: 15px;
        height: 15px;
        margin: 0;
        margin-left: 10px;
      }

        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      display: block;
      background: url('~@/assets/newDistrict/rightTop_Tit.png') no-repeat center center;
      width: 582px;
      height: 33px;
    }
  }

  .center {
    width: 100%;
    height: 344px;

    .center_content {
      padding: 0 21px 0 27px;

      img {
        margin: 20px 0 28px;
      }

      .list {
        padding-bottom: 10px;

        .title {
          color: #28d4f5;
          font-size: 16px;
          width: 100%;
          font-weight: 500;
          padding-left: 9px;
          margin-bottom: 15px;
        }

        .swiper_content {
          overflow: hidden;
          height: 180px;
          width: 100%;

          .swiper-slide {
            background: rgba(68, 202, 255, 0.4);
            padding: 10px;
            height: 30px;
            color: white;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 30px;

            .el-col-7 {
              padding-left: 10px;

              span {
                width: 50px;
              }
            }

            .el-col-8 {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

          .tip {
            width: 15px;
            height: 15px;
            margin: 0;
            margin-left: 10px;
          }

          .swiper-slide:nth-child(2n) {
            background: transparent;
          }
        }

      }



    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 24px;
      background: url('~@/assets/newDistrict/borderLine.png') no-repeat center center;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 24px;
      background: url('~@/assets/newDistrict/borderLine.png') no-repeat center center;
    }
  }

  .bottom {
    padding: 20px 21px 0 27px;

    .content {
      height: 300px;
      padding: 25px 29px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: stretch;

      p {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        color: #44CAFF;

        &:last-child {
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #FDFDFD;
          margin-top: 15px !important;
        }
      }

      .box {
        width: 50%;
        height: 100px;
        display: flex;
        align-items: center;

        img {
          width: 90px;
          height: 85px;
          margin-right: 25px;
        }

        p {
          color: #FFFFFF;
        }


      }
    }
  }
}
</style>
