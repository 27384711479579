<template>
  <div class='city'>
    <header-top ref="headerTop" />
    <div class="content">
      <Left ref="left"/>
      <Center ref="center"/>
      <Right ref="right"/>
      <!-- <left-top ref="leftTop" /> -->
      <!-- <center-top ref="centerTop"/> -->
      <!-- <right-top ref="rightTop" /> -->
    </div>
    <!-- <div class="content"> -->
      <!-- <left-bottom ref="leftBottom" /> -->
      <!-- <center-bottom /> -->
      <!-- <right-bottom /> -->
    <!-- </div> -->
  </div>
</template>

<script>
import HeaderTop from './components/header'
import Left from './components/left'
import Right from './components/right'
import Center from './components/center'

// import CenterBottom from './components/centerBottom'
// import CenterTop from './components/centerTop'
// import LeftBottom from './components/leftBottom'
// import LeftTop from './components/leftTop'
// import RightBottom from './components/rightBottom'
// import RightTop from './components/rightTop'
import { getAreaList,getCountStatistic } from '@/api'
export default {
  // components: { HeaderTop, CenterBottom, CenterTop, RightBottom, RightTop, LeftBottom, LeftTop },
  components: { HeaderTop, Left,Right,Center
    // LeftTop, RightTop, CenterTop, LeftBottom, CenterBottom, RightBottom 
  },
  data() {
    return {

    };
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    init() {
      getAreaList().then(res => {
        this.$refs.headerTop.getTit(res.data)
        this.$refs.right.init(res.data)
        this.$refs.left.init(res.data)
        this.$refs.center.mapData(res.data)
      })
      getCountStatistic().then(res => {
        this.$refs.center.init(res.data)
        this.$refs.headerTop.getRunTime(res.data)
        this.$refs.left.getbottomData(res.data)
      })
    }
  }
};
</script>

<style scoped lang="scss">
.city {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  background: url("../../assets/city/bg.png") no-repeat;
  background-size: 100% 100%;

  .content {
    padding: 0 24px 24px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
