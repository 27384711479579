<template>
  <div class='box'>
    <div class='boxTit'></div>
    <div class='riskBox'>
        <div class='riskItem' style="background:rgba(252, 105, 76, 0.8)">
          <p>风险推送事件</p>
          <p>{{itemMsg.total}}</p>
        </div>
        <div class='riskItem' style="background:rgba(233, 165, 255, 0.8)">
          <p>转执法</p>
          <p>{{itemMsg.law}}</p>
        </div>
        <div class='riskItem' style="background:rgba(40, 217, 250, 0.8)">
          <p>解除风险</p>
          <p>{{itemMsg.relieve}}</p>
        </div>
    </div>
    <div class="substance">
      <el-row class="citycaption">
        <el-col :span="8">酒店名称</el-col>
        <el-col :span="9">风险内容</el-col>
        <el-col :span="7">推送时间</el-col>
      </el-row>
      <div class="swiper swiper_city" v-if="rankList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in rankList" class="swiper-slide" :key="index">
            <el-col :span="8">{{el.siteName}}</el-col>
            <el-col :span="9">{{el.message}}</el-col>
            <el-col :span="7">{{el.time}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAlarm ,getWorkTotal} from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data() {
    return {
      rankList: [],
      itemMsg:{}
    }
  },
  created() {
    this.init()
    this.data()
  },
  computed: {
  },
  methods: {
    init () {
      getAlarm(localStorage.getItem('areaCode')).then(res => {
        const arr = res.data.sort((a, b) => {
          return a.date > b.date ? -1 : 1
        })
        arr.map(el => {
          if (el.date.trim().split(" ")[1] === '00:00:00') {
            el.time = el.date.trim().split(" ")[0]
          } else {
            el.time = el.date
          }
        })
        this.rankList = arr
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.rankList && this.rankList.length > 6) {
          new Swiper('.swiper_city', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.rankList && this.rankList.length <= 6) {
          new Swiper('.swiper_city', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    },
    data(){
      const start = this.$moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD");
      const stop = this.$moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD");
      getWorkTotal(start, stop).then(res => {
        this.itemMsg=res.data
      })
    }
  }
}
</script>
<style scoped lang='scss'>
*{
  box-sizing: border-box;
}
.box{
  width: 100%;
  height: 382px;
  background: url('~@/assets/newDistrict/center.png') no-repeat center center;
  padding:14px 0;
  background-size: 100% 100%;

  .boxTit{
    padding-left:27px;
    &::before{
      content: "";
      display: inline-block;
      width: 581px;
      height: 33px;
      background: url('~@/assets/newDistrict/center_tit.png') no-repeat;
    }
  }
  .riskBox{
    padding:20px 24px ;
    display:flex;
    align-items: center;
    justify-content: space-around;
    .riskItem{
      width:120px;
      height:68px;
      background:#fff;
      border-radius: 10px;
      text-align: center;
      font-family: Source Han Sans CN;
font-weight: 400;
font-size: 16px;
color: #FDFDFD;
padding:10px 0;
p:last-child{
  font-weight: 400;
font-size: 28px;
color: #FFFFFF;
}
p:last-child::after{
  content:'个';
  display: inline-block;
  font-size: 16px;
color: #FDFDFD;
line-height: 23px;

}
    }
  }
  .citycaption {
    color: #28d4f5;
    font-size: 16px;
    width: 100%;
    padding-left: 9px;
    font-weight: 500;
  }
  .substance {
    box-sizing: border-box;
    padding: 0 24px 24px 24px;
    width: 100%;
  }
}
.swiper_city {
  overflow: hidden;
  height: 160px;
  margin-top: 15px;
  .swiper-slide {
    height: 30px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    .el-col-8 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }

  .swiper-slide:nth-child(2n) {
    background: rgba(68, 202, 255, 0.4);
  }
}

</style>
