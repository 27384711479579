<template>
  <div class="cityheader">
    <div class="content">
      <div class="left">
        <i :class="`iconfont ${icon}`"></i>
        <div>{{ leftName }}</div>
        <p class="line"></p>
        <div><span>{{ temperature }}℃</span> <span style="margin-left: 10px;">{{ weather }}</span></div>
      </div>
      <div class="right">
        <div>{{ this.$moment(time).format('YYYY-MM-DD HH:mm:ss') }}</div>
        <div class="timeBox">
          <div>运行 <span style="color:#00FFEA ;">{{ count.runtime }}</span> 天</div>
        </div>
        <div class="goback" @click="goback">
          <i></i>
          <span>返回平台</span>
        </div>
      </div>
    </div>
    <div class="center">
      智慧酒店保洁平台
    </div>
  </div>
</template>

<script>
var interval
import AMap from 'AMap'
import { mapGetters } from 'vuex'
import { environment } from '@/utils/config.js'
export default {
  data() {
    return {
      time: this.$moment(),
      leftName: '',
      temperature: 0,
      weather: '晴',
      icon: null,
      title: '',
      count: {},
      timer: null,
    };
  },
  watch: {
    title(newVal) {
      if (newVal) {
        this.initWeather()
      }
    }
  },
  created() {
    interval = setInterval(() => this.time = new Date(), 1000);

  },
  mounted() {
    this.timer = setInterval(() => { this.initWeather() }, 18000000);
  },
  destroyed() {
    clearInterval(interval)
    clearInterval(this.timer)

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    // 获取天气
    initWeather() {
      // debugger
      const _this = this
      AMap.plugin('AMap.Weather', function () {
        //创建天气查询实例
        var weather = new AMap.Weather();
        //执行实时天气信息查询
        const areaName = localStorage.getItem('areaName') == '钱塘新区' ? '钱塘区' : localStorage.getItem('areaName')

        weather.getLive(areaName, function (err, data) {
          let iconfont = 'icon-tianqiyubao'
          if (!err) {
            if (data.weather.indexOf('阴') !== -1) iconfont = 'icon-yin'
            if (data.weather.indexOf('晴') !== -1) iconfont = 'icon-qing'
            if (data.weather.indexOf('云') !== -1) iconfont = 'icon-duoyun'
            if (data.weather.indexOf('雨') !== -1) iconfont = 'icon-yu'
            if (data.weather.indexOf('雪') !== -1) iconfont = 'icon-zhenxue'
            if (data.weather.indexOf('风') !== -1) iconfont = 'icon-feng'
            if (data.weather.indexOf('霾') !== -1) iconfont = 'icon-mai'
            if (data.weather.indexOf('雾') !== -1) iconfont = 'icon-wu'
            if (data.weather.indexOf('沙') !== -1) iconfont = 'icon-qiangshachenbao'
            document.title = `${data.city}“住宿卫生在线”`
            _this.icon = iconfont,
              _this.temperature = data.temperature,
              _this.weather = data.weather
            if (data.city === '温州市') {
              _this.title = '温州市'
              _this.leftName = data.city

            } else if (data.title == '钱塘区') {
              _this.title = '钱塘新区'
              _this.leftName = '钱塘新区'

            } else {
              _this.title = data.city
              _this.leftName = data.city

            }

          }

        });
      });
    },
    goback() {
      // const IP = environment === 'TE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      const IP = environment === 'DE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // const type = localStorage.getItem('type')
      // if (type) {
      // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // } else {
      window.open(`${IP}/app/hotels`)
      // window.open(`http://ivatest.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // }
    },
    getTit(data) {
      const name = data[0].name.split(',')
      if (name[name.length - 1] === '莲都区') {
        localStorage.setItem('type', 'ld')
        localStorage.setItem('hoteltitle', '民宿')
      } else {
        localStorage.setItem('hoteltitle', '酒店')
        localStorage.setItem('type', '')
      }
      localStorage.setItem('areaName', name[name.length - 1])
      localStorage.setItem('areaCode', data[0].adcode)
      this.title = name[name.length - 1]
    },
    getRunTime(val) {
      this.count = val
    }
  }
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.cityheader {
  width: 100%;
  height: 95px;
  background: url("~@/assets/city/header.png") no-repeat center center;
  background-size: 100% 100%;
  padding: 15px 24px 0 24px;
  margin-bottom: 3px;
  position: relative;

  .content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .center {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 50%);
    font-size: 36px;
    color: transparent;
    background-image: linear-gradient(317deg, #afeede, #a3c9df, #bad0f2);
    background-clip: text;
    font-weight: bold;
    background-image: -webkit-linear-gradient(315deg,  #afeede, #a3c9df, #bad0f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .left {
    width: 33%;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;

    .iconfont {
      font-size: 24px;
      color: white;
      margin-right: 12px;
    }

    // .weather {
    // display: flex;
    // align-items: center;
    // justify-content: start;
    //   p {
    //     font-size: 16px;
    //     font-weight: 500;
    //     color: #FFFFFF;
    //   }

    //   p:nth-child(2) {
    //     font-size: 12px;
    //     margin-top: 10px;

    //     span {
    //       margin-right: 17px;
    //     }
    //   }
    // }

    .line {
      width: 1px;
      height: 27px;
      background: #0e6698;
      margin: 0 13px;
    }
  }

  .right {
    width: 33%;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: end;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;

    .timeBox {
      margin-left: 20px;
    }

    .goback {
      margin-left: 50px;
      background: url("../../../../assets/city/back_bg.png") no-repeat;
      background-size: 100% 100%;
      width: 92px;
      height: 41px;
      line-height: 41px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        display: block;
        background: url("../../../../assets/city/back_icon.png") no-repeat;
        background-size: 100% 100%;
        width: 9px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>
