<template>
  <div class="center_page">
    <div class="top">
      <div class="card">
        酒店数量
        <countTo :startVal='0' :endVal='count.siteCount' :duration='1000' separator="" />
      </div>
      <div class="card">
        三类酒店数量
        <countTo :startVal='0' :endVal='count.threeTypeSiteCount' :duration='1000' separator="" />
      </div>
    </div>
    <Map ref="map"/>
    <Bottom />



  </div>
</template>
<script>
import countTo from 'vue-count-to';
// import { mapGetters } from 'vuex'
import Map from './map.vue'
import Bottom from './bottom.vue'
export default {
  components: { countTo, Map,Bottom },
  data() {
    return {
      count: {
        siteCount: null,
        onlineSiteCount: null,
        riskTotalCount: null,
        onlineRate: null,
        threeTypeSiteCount: null
      },
    }
  },
  computed: {
  },
  methods: {
    init(val) {
      this.count = val
    },
    mapData(val) {
      this.$refs.map.init(val)
    }
  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.center_page {
  flex: 1;

  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .card {
      width: 170px;
      height: 70px;
      background: url('~@/assets/city/centerCard.png') no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px;

      span {
        font-size: 32px;
        font-weight: bold;
        color: #3AFFE4;
      }
    }
  }
}
</style>
