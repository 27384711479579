<template>
  <div class='district_map'>
    <div class="map_col">
      <div id="main"></div>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
export default {
  data () {
    return {
      totalData: [],
      title: localStorage.getItem('hoteltitle'),
      areaList: [],
      echartsMap: null,
      map: null,
      // geoJsonData: null,
      cityName: '中国',
      mapData: [],
      cityCode: null,
      district: null,
      upperCityAdcode: null
    };
  },
  created () {

  },
  mounted () {
    // this.init()
  },
  methods: {
    init (val) {
      this.areaList = val
        this.upperCityAdcode = val[1].adcode
        this.initMap(val[0])
    },
    // 初始化地图
    initMap (val) {
      let _this = this
      this.echartsMap = this.$echarts.init(document.getElementById('main'));
      AMap.plugin('AMap.DistrictSearch', function () {
        _this.district = new AMap.DistrictSearch({
          subdistrict: 1,
          showbiz: false
        })
        if (val.adcode === '330621') {
          val.adcode = '330603'
        }
        // 搜索所有省/直辖市信息
        _this.district.search(val.adcode, function (status, result) {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete') {
            _this.cityName = result.districtList[0].name
            _this.cityCode = result.districtList[0].adcode
            _this.getData(result.districtList[0], '', result.districtList[0].adcode);
          }
        })
      })
      // this.map = new AMap.Map('container', {
      //   resizeEnable: true,
      //   center: [116.30946, 39.937629],
      //   zoom: 3
      // })
    },
    getData (data) {
      var subList = data.districtList;
      // let geoJsonData = this.geoJsonData
      // let cityName = this.cityName
      let mapData = this.mapData
      if (subList) {
        // console.log('subList :>> ', subList);
        var curlevel = subList[0].level;
        if (curlevel === 'street') {
          // console.log(geoJsonData)
          let map = this.map
          // let geoJsonData = this.geoJsonData
          // let cityName = this.cityName
          AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
            //创建一个实例
            var districtExplorer = window.districtExplorer = new DistrictExplorer({
              eventSupport: true, //打开事件支持
              map: map
            });
            // console.log('this.upperCityAdcode :>> ', this.upperCityAdcode);
            districtExplorer.loadAreaNode(this.upperCityAdcode, (error, areaNode) => {
              // debugger
              if (error) {
                // console.error(error);
                return;
              }
              let mapJson = {};
              let mapJsonList = areaNode.getSubFeatures();
              mapData = []
              // let cityArea = JSON.parse(JSON.stringify(this.cityArea))
              for (let i in mapJsonList) {
                if (mapJsonList[i].properties.name == this.cityName) {
                  mapJson.type = "FeatureCollection";
                  mapJson.features = [].concat(mapJsonList[i]);
                }
              }
              // const filterData = cityArea.filter(el => el.districtName === this.cityName)[0]
              mapData.push({ name: this.cityName, value: Math.random() * 100, level: curlevel });
              this.mapData = mapData
              // console.log('object :>> ', this.cityName, mapJson, mapData);
              this.loadMap(this.cityName, mapJson)
            });
          });
        }
      }
    },
    loadMap (mapName, data) {
      let echartsMap = this.echartsMap
      const areaList = this.areaList
      const dotList = []
      areaList[0].siteList.map(el => {
        dotList.push({
          name: el.sitename,
          value: [el.longitude, el.latitude],
          phone: el.phone,
          roomQuantity: el.roomQuantity,
          cameraQuantity: el.cameraQuantity,
          boardQuantity: el.boardQuantity
        })
      })
      if (data) {
        this.$echarts.registerMap(mapName, data);
        var option = {
          tooltip: {
            show: false,
            trigger: 'item'
          },
          /*backgroundColor: '#000f1e',*/
          geo: {
            map: mapName,
            aspectScale: 0.85,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            itemStyle: {
              // normal: {
              shadowColor: '#00B8BE',
              shadowOffsetX: 0,
              shadowOffsetY: 15,
              opacity: 0.3,
              // },
              emphasis: {
                areaColor: '#276fce',
              }
            },

          },
          series: [
            // 常规地图
            {
              type: 'map',
              map: mapName,
              // roam: 'scale',、
              aspectScale: 0.85,
              layoutCenter: ["50%", "50%"], //地图位置
              layoutSize: '100%',
              zoom: 1, //当前视角的缩放比例
              scaleLimit: { //滚轮缩放的极限控制
                min: 1,
                max: 2
              },
              itemStyle: {
                // normal: {
                areaColor: '#003C8B',
                borderColor: '#1cccff',
                borderWidth: 1.5,
                opacity: 0.3,
                emphasis: {
                  areaColor: '#02102b',
                  label: {
                    color: "#fff"
                  }
                }
              },
              label: {
                show: true,
                color: 'white'
              },
              data: this.mapData,
            },
            // 区域散点图
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // showEffectOn: 'emphasis',
              zlevel: 2,
              symbolSize: 6,
              rippleEffect: { //坐标点动画
                period: 2,
                scale: 4,
                // brushType: 'stroke'
              },
              label: {
                // normal: {
                show: false,
                position: 'right',
                formatter: '{b}',
                color: '#b3e2f2',
                fontWeight: "bold",
                fontSize: 16
                // }
              },


              itemStyle: { //坐标点颜色
                // normal: {
                show: true,
                color: '#00FF00',
                shadowBlur: 20,
                shadowColor: '#fff',
                // },
                emphasis: {
                  areaColor: '#f00',
                  focus: 'series'
                }
              },
              data: dotList,
              tooltip: {
                show: true,
                formatter: function (params) {
                  // console.log(params)
                  return `
                <div class="tooltip">
          <div class="city"><i></i>${params.name}</div>
          <div class="content">
            <div>
              <i></i>
              <p>
                摄像头：
                <span> ${params.data.cameraQuantity} </span>
                个
              </p>
            </div>
            <div>
              <i></i>
              <p>
                插座：
                <span> ${params.data.boardQuantity} </span>
                个
              </p>
            </div>
            <div>
              <i></i>
              <p>
                消毒间：
                <span> ${params.data.roomQuantity} </span>
                个
              </p>
            </div>
          </div>
        </div>
              `
                  // console.log(params, ticket)
                },
                backgroundColor: 'rgba(40, 86, 148, .5)',
                borderColor: 'rgba(40, 86, 148, .5)',
                padding: 0
              }

            }
          ]
        }
        echartsMap.setOption(option);
        this.echartsMap = echartsMap
      }
    }
    // loadMapData (areaCode) {
    //   let map = this.map
    //   let geoJsonData = this.geoJsonData
    //   let cityName = this.cityName
    //   AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {

    //     //创建一个实例
    //     var districtExplorer = window.districtExplorer = new DistrictExplorer({
    //       eventSupport: true, //打开事件支持
    //       map: map
    //     });

    //     districtExplorer.loadAreaNode(areaCode, (error, areaNode) => {

    //       if (error) {
    //         console.error(error);
    //         return;
    //       }
    //       let mapJson = {};
    //       mapJson.type = "FeatureCollection";
    //       mapJson.features = areaNode.getSubFeatures();
    //       this.loadMap(cityName, mapJson);
    //       geoJsonData = mapJson;
    //       // console.log(geoJsonData)
    //       this.geoJsonData = geoJsonData
    //     })
    //   })
    // }

  }
};
</script>

<style scoped lang="scss">
.district_map {
  padding: 20px;
  .map_col {
    width: 100%;
    height: 467px;
    #main {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
